import React from "react"
import { Row, Col, Container } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"
import ImageFluid from "../../components/misc/image-fluid"

const Hero = ({ intl, theme, content }) => (
    <div className={`hero ` + theme}>
        <div className={`clipped-area`}></div>
        <div className={`non-clipped-area`}>
            <Container>
                <Row>
                    <Col lg={6}>
                        <h1>{content.title}</h1>
                        <p className={`text`} dangerouslySetInnerHTML={{__html: content.text}}></p>
                        {content.button &&
                        <a href={content.button.url} className={`btn btn-lg btn-secondary button`}>{content.button.label}</a>
                        }
                    </Col>
                    <Col lg={6}>
                        <div className={`image`}>
                            <ImageFluid alt={content.title} filename={content.image} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
)

export default injectIntl(Hero)
